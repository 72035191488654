import * as React from 'react'

import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { IconButton, Typography } from '@mui/material'

import BenjaminImage from '../images/team_members/benjamin.png'
import JuanFranciscoImage from '../images/team_members/juan_francisco.png'
import JulianImage from '../images/team_members/julian.png'
import NicolasImage from '../images/team_members/nicolas.png'
import RomainImage from '../images/team_members/romain.png'
import SebastianImage from '../images/team_members/sebastian.png'

type TeamMemberDetailsProps = {
  name: string
  companyRole: string
  linkedinUrl: string
}

const TeamMemberDetails = ({
  name,
  companyRole,
  linkedinUrl,
}: TeamMemberDetailsProps): React.ReactNode => (
  <React.Fragment>
    <Typography
      variant='h6'
      component='div'
      style={{ textAlign: 'left' }}
    >
      {name}
    </Typography>
    <Typography
      variant='body2'
      style={{ textAlign: 'left' }}
    >
      {companyRole}
    </Typography>
    <IconButton
      href={linkedinUrl}
      target='_blank'
      size='small'
      style={{ marginLeft: '-8px' }}
    >
      <LinkedInIcon />
    </IconButton>
  </React.Fragment>
)

const teamMembers = [
  {
    title: 'Sebastian Saá',
    hoverText: 'Originario de Punta Arenas, es el CEO de Sugarblock y un ingeniero ' +
               'comercial con un talento único: puede imitar el canto de los pájaros ' +
               'con una precisión sorprendente. Con experiencia en la industria del SaaS ' +
               'B2B, lidera Sugarblock con su visión empresarial y pasión por la innovación. ' +
               'Además, comparte su conocimiento como profesor de innovación y ' +
               'emprendimiento en la UAI.',
    image: SebastianImage,
    content: (
      <TeamMemberDetails
        name='Sebastian Saá'
        companyRole='CEO'
        linkedinUrl='https://www.linkedin.com/in/ssaaruiz/'
      />),
  },
  {
    title: 'Julian Arnold',
    hoverText: 'Julian, nuestro capo financiero llegó desde Mendoza con un MBA del IE de ' +
               'Madrid. Además de su experiencia como trader y gestor independiente de ' +
               'portafolios en FX y mercados cripto, también dejó su huella en la banca de ' +
               'inversión. Julian llevó su conocimiento a otro nivel en el mundo de la ' +
               'criptominería: con visión estratégica, importó e instaló 1492 máquinas de ' +
               'minería desde China a Venezuela en 2018, arrancando el negocio sin invertir ' +
               'un solo dólar.',
    image: JulianImage,
    content: (
      <TeamMemberDetails
        name='Julian Arnold'
        companyRole='CFO'
        linkedinUrl='https://www.linkedin.com/in/jaz89/'
      />),
  },
  {
    title: 'Juan Francisco Rebolledo',
    hoverText: 'El genio amigable que encuentras en la oficina, siempre dispuesto a ayudar. ' +
               'Estudió ingeniería civil eléctrica en la U. de Chile y no se conformó con ' +
               'menos que una distinción máxima en su tesis. Desde que tenía 14 años, su ' +
               'curiosidad lo llevó a probar con códigos, comenzando por hackear el ' +
               'GTA San Andreas, y desde entonces no ha parado. Fue el arquitecto detrás ' +
               'del sistema de mensajería de Entel y Movistar México, demostrando que siempre ' +
               'está un paso adelante.',
    image: JuanFranciscoImage,
    content: (
      <TeamMemberDetails
        name='Juan Francisco Rebolledo'
        companyRole='CTO'
        linkedinUrl='https://www.linkedin.com/in/juan-francisco-rebolledo/'
      />),
  },
  {
    title: 'Nicolás Cumsille',
    hoverText: 'Ingeniero Civil Industrial de profesión, se destacó en su tesis con ' +
               'una distinción máxima. Ha trabajado como Growth & Brand Manager en ' +
               'grandes empresas de diversos sectores. Cuando no está trabajando, ' +
               'le encanta disfrutar de la naturaleza junto a su perro Plomo, un husky ' +
               'que lo acompaña en sus paseos.',
    image: NicolasImage,
    content: (
      <TeamMemberDetails
        name='Nicolás Cumsille'
        companyRole='MVP'
        linkedinUrl='https://www.linkedin.com/in/nicolas-cumsille/'
      />),
  },
  {
    title: 'Romain Delaunay',
    hoverText: 'Software Engineer francés, con pasiones diversas como el arte, ' +
               'la carpintería y el boxeo. Residiendo en Chile desde hace 5 años, ' +
               'busca fusionar creatividad y tecnología en cada proyecto.',
    image: RomainImage,
    content: (
      <TeamMemberDetails
        name='Romain Delaunay'
        companyRole='Software Engineer'
        linkedinUrl='https://www.linkedin.com/in/romain-delaunay-92438725a/'
      />),
  },
  {
    title: 'Benjamin Herrera',
    hoverText: 'Ingeniero comercial con una pasión por la tecnología blockchain y las ' +
               'criptomonedas. Con experiencia como trader en el mercado de criptomonedas, ' +
               'ha demostrado habilidades excepcionales en el análisis de mercado y la ' +
               'toma de decisiones financieras. Su profundo interés en el ecosistema DeFi ' +
               'lo ha llevado a explorar y contribuir activamente a proyectos innovadores ' +
               'en este campo.',
    image: BenjaminImage,
    content: (
      <TeamMemberDetails
        name='Benjamin Herrera'
        companyRole='Money Maker'
        linkedinUrl='https://www.linkedin.com/in/benjamin-h-a15a8b1a4/'
      />),
  },
]

export default teamMembers
