import React from 'react'

import { Container } from '@mui/material'

import { Carousel, Section, SectionWrapper, SeoHeaders, Title } from 'shared/components'

import teamMembers from '../data/team_members'
import Layout from '../layouts/landing'

const Team = () => {
  return (
    <Layout>
      <SeoHeaders
        title='¿Quienes somos?'
        description='Quién es el equipo detrás de Sugarblock'
      />
      <Container maxWidth='lg'>
        <SectionWrapper>
          <Section>
            <Title
              mainTitle
              textAlign='center'
            >
              ¿Quiénes somos?
            </Title>
          </Section>
          <Carousel data={teamMembers} />
        </SectionWrapper>
      </Container>
    </Layout>
  )
}

export default Team
